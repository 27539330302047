<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" class="bg-dark shadow" type="dark" variant="info">
        <b-navbar-brand href="#">
          <span style="color:#CC66FF">M</span>
          <span style="color:cornflowerblue">elody</span>
          <span style="color:#CC66FF">S</span>
          <span style="color:cornflowerblue">ky</span>
          <span style="color:#CC66FF">&nbsp;C</span>
          <span style="color:cornflowerblue">onsole</span>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <router-link to="/BuySearch" active-class="active" style="text-decoration:none;"
              class="nav-link">订单查询</router-link>
            <router-link to="/AddNewID" active-class="active" style="text-decoration:none;"
              class="nav-link">添加新ID</router-link>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!-- 路由 -->
    <div class="container" style="padding:40px 20px 0px 20px;">
      <router-view class="animate__animated animate__fadeIn "></router-view>
    </div>

    <!-- <div class="fixed-bottom d-flex justify-content-center bg-dark text-white" style="padding:10px;">本站维护：没人维护</div> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {

    }
  },
}
</script>

<style></style>

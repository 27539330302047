<template>
  <div class="text-white">
    <b-alert show variant="info">
      添加新绑定（请输入爱发电订单号与购买时填写的ID）请点击一次 如果太久没反映请刷新界面
      <b-overlay rounded="xl" variant="dark" opacity="1.0">
        <b-row cols="1" cols-sm="2" cols-md="4" cols-lg="2">
          <b-col>
            <b-input-group prepend="订单号:" class="mt-3">
              <b-form-input type="password" v-model="Order"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group prepend="最开始的MCID:" class="mt-3">
              <b-form-input v-model="MinecraftID"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group prepend="新增的MCID:" class="mt-3">
              <b-form-input v-model="NewMinecraftID"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <!-- <b-button @click="Search()" class="mt-3" style="width:48%; margin-right: 1%;">
              <span>查看已绑定</span>
            </b-button> -->
            <b-button @click="AddNewID()" class="mt-3" style="width:100%;">
              <span>添加新绑定</span>
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-alert>
    <div>
      <b-modal id="addID" :title="ModalTitle">
        <p class="my-4">{{ ModalText }}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddNewID',
  data() {
    return {
      Order: '',
      MinecraftID: '',
      NewMinecraftID: '',
      ModalTitle:"...",
      ModalText:""
    }
  },
  methods: {
    AddNewID() {
      const axios = require('axios');
      // Axios
      axios.get(`http://melody.nigger.cool/System/AddNewID.php?OrderNumber=${this.Order}&MinecraftID=${this.MinecraftID}&NewMinecraftID=${this.NewMinecraftID}`)
        .then(response => {

          this.ModalTitle = response.data.code;
          this.ModalText = response.data.text;
          this.$bvModal.show("addID");
        })
    }
  },
  // mounted: function(){

  // }
}
</script>

<style></style>
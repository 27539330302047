<template>
  <div>
    <b-alert show variant="info" style="padding: 15px 15px 0px 20px;">
      <h4 class="alert-heading">注意！</h4>
      <p>
        必须在爱发电支付成功后才会看到记录，如填入了正确ID并成功付款请添加群：821173022 并发送订单号以用于验证，没有购买的可以前往：<a href="https://afdian.net/item/e827eefe168311eea3645254001e7c00">点击前往购买界面</a>
      </p>
      <p>
        购买时请填写你正常的ID（不是UUID）不然会导致上白名单失败，一次仅可以填写一个ID
      </p>
    </b-alert>

    <b-table striped hover :items="Order" dark="dark" borderless="borderless" style="border-radius:5px;"></b-table>

  </div>
</template>

<script>
export default {
  name: 'BuySearch',
  data() {
    return {
      Order: [
        { "Time/购买时间":"加载中","MinecraftID/白名单ID":"加载中" }
      ]
    }
  },
  mounted: function () {
    const axios = require('axios');
      // 向给定ID的用户发起请求
      axios.get(`http://melody.nigger.cool/System/BuySearch.php`)
        .then(response => {
          this.Order = response.data;
        })
        .catch(function (error) {
          window.alert(`未知错误，请联系棱镜，${error}`);
        })
  }
}
</script>

<style></style>

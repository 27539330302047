// 先引入路由文件
import VueRouter from "vue-router";
import BuySearch from "../components/BuySearch"
import AddNewID from "../components/AddNewID"

// 创建路由器
export default new VueRouter({
    routes: [
        {
            path: '/', // 重定向
            redirect: '/BuySearch'
        },
        {
            path: '/BuySearch',	// 路径
            component: BuySearch // 定义组件
        },
        {
            path: '/AddNewID',
            component: AddNewID
        }
    ]
})